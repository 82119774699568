import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {userNotify} from '../../../../components/Toasts/ToastAdd';
import _ from 'lodash'

// Import Actions
import {
  loginUser,
  requestOTPLogin,
  authenticateOTP,
  logoutUser,
  editLoginNumber,
  togglePasswordOtpLogin
} from '../../AppActions';
import {
  getOtpSentState,
  getOTPRequestState,
  getAuthenticatedState,
  getActiveVenueId,
  getOTPVerifyingState,
  getPasswordProcessingState
} from '../../AppReducer';
import isNumeric from "validator/lib/isNumeric";
import isEmail from "validator/lib/isEmail";
import SpinnerLoader from '../../../../components/Loader/Spinner';

const otpResendTime = 30;
let interval = 1000;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOtpLogin: false,
      isShowPassword: false,
      isResendAllowed: false,
      resendTime: otpResendTime,
      username: '',
      isOnlyPhone: false,
      invalidUsername: false,
      invalidPassword: false,
      isTimerRunning: false
    };
  };

  // componentDidMount() {
  //     this.handleResendTime();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.isOTPSent && prevProps.isOTPSent) {
      if (this.props.isOTPSent) {
        this.handleResendTime();
      } else {
        this.clearTimer();
      }
    }
  }

  clearTimer = () => {
    clearInterval(this.timer);
    this.setState({ isTimerRunning: false });
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  handleLogin = () => {
    let data = {};
    data.username = this.username.value;
    data.password = this.userpassword.value;
    if (isNumeric(data.username)) {
      if (data.username.length !== 10) {
        this.showMessage('Please provide a proper phone number', 'warning');
        this.setState({invalidUsername: true});
      } else if (!(data.password.length) > 0) {
        this.showMessage('Please provide a proper password', 'warning');
        this.setState({invalidPassword: true});
      } else {
        this.props.dispatch(loginUser(data));
      }
    } else {
      if (!isEmail(data.username)) {
        this.showMessage('Please provide a proper email', 'warning');
        this.setState({invalidUsername: true});
      } else if (!(data.password.length) > 0) {
        this.showMessage('Please provide a proper password', 'warning');
        this.setState({invalidPassword: true});
      } else {
        this.props.dispatch(loginUser(data));
      }
    }
  };

  showMessage = (msg, type) => {
      userNotify(msg, type);
  };


  handleRequestOTP = () => {
    if (!this.state.isTimerRunning) {
      this.handleResendTime();
    }
    let creds = {};
    creds.username = this.userPhone.value;
    if (isNumeric(creds.username)) {
      if (creds.username.length !== 10) {
        this.showMessage('Please provide a proper phone number', 'warning');
        this.setState({invalidUsername: true})
      } else {
        let body = {
          creds: creds,
          service: 'sms'
        }
        this.props.dispatch(requestOTPLogin(body));
      }
    } else {
      if (!isEmail(creds.username)) {
        this.showMessage('Please provide a proper email', 'warning');
        this.setState({invalidUsername: true})
      } else {
        let body = {
          creds: creds,
          service: 'email'
        }
        this.props.dispatch(requestOTPLogin(body));
      }
    }
  };


  validateOTP = () => {
    let creds = {};
    creds.username = this.userPhone.value;
    creds.code = this.userotp.value;
    if (isNumeric(creds.username)) {
      if (creds.username.length !== 10) {
        this.showMessage('Please provide a proper phone number', 'warning');
        this.setState({invalidUsername: true})
      } else {
        this.props.dispatch(authenticateOTP(creds));
      }
    } else {
      if (!isEmail(creds.username)) {
        this.showMessage('Please provide a proper email', 'warning');
        this.setState({invalidUsername: true})
      } else {
        this.props.dispatch(authenticateOTP(creds));
      }
    }
  };

  handleLogout = () => {
    this.props.dispatch(logoutUser());
  };

  handleShowHidePassword = () => {
    this.setState({isShowPassword: !this.state.isShowPassword});
  };

  handleToggleLoginMethod = () => {
    this.setState({
      isOtpLogin: !this.state.isOtpLogin,
      resendTime: otpResendTime,
    }, () => {
      this.props.dispatch(togglePasswordOtpLogin());
    });
  };


  handleEditMobileNumber = () => {
    clearInterval(interval);
    this.props.dispatch(editLoginNumber());
  };


  handleResendOTP = (e) => {
    if (this.state.resendTime > 0 || this.props.isRequestingOtp) {
      e.preventDefault();
    } else {
      this.setState({
        resendTime: otpResendTime,
      }, () => {
        this.handleRequestOTP();
      });
    }
  };


  handleResendTime = () => {
    if (!this.state.isTimerRunning) {
      this.setState({ isTimerRunning: true }, () => {
        this.timer = setInterval(() => {
          if (this.state.resendTime > 0) {
            this.setState({
              resendTime: this.state.resendTime - 1,
            });
          } else {
            this.clearTimer(); // Clear the timer when it reaches 0
          }
        }, interval);
      });
    }
  }


  handleUserNameChange = (e) => {
    let username = e.target.value;
    username = _.toString(username);
    let isPhoneOnly = isNumeric(username);
    this.setState({
      username: e.target.value,
      isPhoneOnly: isPhoneOnly
    });
  };

  render() {
    let content, otpLogin, passwordLogin;

    otpLogin = <div className="col-sm-12">
      <div style={{position: 'relative', display: 'block'}}>
        {
          this.props.isOTPSent ?
            <span className="pull-right" onClick={() => this.handleEditMobileNumber()}
                  style={{color: '#0057FF', cursor: 'pointer'}}>Edit Number</span> :
            <label>Please enter your email ID/contact number</label>
        }
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="userPhone-addon">
              {this.state.isPhoneOnly ? '+91' : '@'}
            </span>
          </div>
          <input
            type="text"
            style={{borderColor: this.state.invalidUsername ? 'red' : 'silver'}}
            value={this.state.username}
            disabled={!!this.props.isOTPSent}
            className="form-control"
            aria-label="Phone"
            aria-describedby="userPhone-addon"
            onChange={(e) => this.handleUserNameChange(e)}
            ref={(ref) => this.userPhone = ref}
          />
        </div>
      </div>
      {
        this.props.isOTPSent ?
          <div style={{position: 'relative', display: 'block', marginTop: '15px'}}>
            <label>Please enter OTP</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                aria-label="OTP"
                ref={(ref) => {
                  this.userotp = ref
                }}
              />
            </div>
          </div> : null
      }

      <div style={{position: 'relative', display: 'block', width: '100%', marginTop: '20px'}}>
        {
          this.props.isOTPSent ?
            <button className="btn btn-primary full-width" disabled={!!this.props.isVerifyingOTP}
                    onClick={() => this.validateOTP()}>{this.props.isVerifyingOTP ?
              <SpinnerLoader/> : 'VERIFY'}</button> :
            <button disabled={!!this.props.isRequestingOtp} className="btn btn-primary full-width"
                    onClick={() => this.handleRequestOTP()}>{this.props.isRequestingOtp ?
              <SpinnerLoader/> : 'REQUEST OTP'}</button>
        }
      </div>
      {
        this.props.isOTPSent ?
          <div style={{position: 'relative', display: 'block', marginTop: '25px'}}>
            {
              this.state.resendTime === 0 ?
                <div className="col-sm-12 text-center">
                  <span style={{color: '#0057FF', cursor: 'pointer'}} onClick={(e) => this.handleResendOTP(e)}>Resend OTP</span>
                </div> :
                <div className="col-sm-12 text-center">
                  <div style={{color: '#25AD2B'}}>{`${this.state.resendTime}`}</div>
                  <div style={{fontSize: '12px'}}>Wait for resend!</div>
                </div>
            }
          </div> : null
      }
      <div className="col-sm-12 text-center" style={{marginTop: '20px', fontSize: '12px'}}>
        <span>{'Login via password instead?'}</span>
        <span style={{color: '#0057FF', marginLeft: '10px', cursor: 'pointer'}}
              onClick={() => this.handleToggleLoginMethod()}>Click here</span>
      </div>
    </div>;

    passwordLogin = <div className="col-sm-12">
      <div style={{position: 'relative', display: 'block'}}>
        <label>Please enter your email ID/contact number</label>
        <div className="input-group">
          <input
              style={{borderColor: this.state.invalidUsername ? 'red' : 'silver'}}
            type="text"
            className="form-control"
            aria-label="Phone"
            ref={(ref) => this.username = ref}
          />
        </div>
      </div>
      <div style={{position: 'relative', display: 'block', marginTop: '10px'}}>
        <label htmlFor="Password">Password</label>
        <div className="input-group">
          <input
              style={{borderColor: this.state.invalidPassword ? 'red' : 'silver'}}
            type={this.state.isShowPassword ? 'text' : 'password'}
            className="form-control"
            aria-label="Password"
            ref={(ref) => {
              this.userpassword = ref
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text" style={{borderColor: this.state.invalidPassword ? 'red' : 'silver'}} onClick={() => this.handleShowHidePassword()}>
              <i className={`fa ${this.state.isShowPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
            </span>
          </div>
        </div>
      </div>

      <div style={{position: 'relative', display: 'block', width: '100%', marginTop: '20px'}}>
        <button className="btn btn-primary full-width" disabled={!!this.props.isVerifyingPassword}
                onClick={() => this.handleLogin()}>{this.props.isVerifyingPassword ?
          <SpinnerLoader/> : 'LOGIN'}</button>
      </div>
      <div className="col-sm-12 text-center"
           style={{marginTop: '20px', padding: 0, fontSize: '12px', display: 'flex', justifyContent: 'space-between'}}>
        <span>
          <span>{'Get an OTP instead?'}</span>
          <span style={{color: '#0057FF', marginLeft: '10px', cursor: 'pointer'}}
                onClick={() => this.handleToggleLoginMethod()}>Click here</span>
        </span>
        <Link style={{color: '#0057FF', cursor: 'pointer'}} to={'/forgot'}>{'Forget Password?'}</Link>
      </div>
    </div>;
    return (
      <div className="col-sm-12" style={{backgroundColor: '#fff', paddingBottom: '25px', borderRadius: '3px'}}>
        <h5 style={{color: '#000', marginTop: '15px', padding: '15px'}}>Login</h5>
        {
          this.state.isOtpLogin ?
            otpLogin : passwordLogin
        }
        <div className="col-sm-12 text-center" style={{marginTop: '20px', fontSize: '12px'}}>
          <span>Don't have account ?</span>
          <Link to="/list-your-business" style={{color: '#0057FF', marginLeft: '10px', cursor: 'pointer'}}>Sign
            up</Link>
        </div>
      </div>
    );
  }

}

// Actions required to provide data for this component to render in sever side.
// LoginPage.need = [params => {
//     return fetchLeads({id: params.id});
// }];

// Retrieve data from store as props
function mapStateToProps(state, props) {
  return {
    app: state.app,
    activeVenueId: getActiveVenueId(state),
    isOTPSent: getOtpSentState(state),
    isRequestingOtp: getOTPRequestState(state),
    isAuthenticated: getAuthenticatedState(state),
    isVerifyingOTP: getOTPVerifyingState(state),
    isVerifyingPassword: getPasswordProcessingState(state),
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object,
};


export default connect(mapStateToProps)(LoginPage);
