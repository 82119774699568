import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import LoginForm from '../../../components/Login/LoginForm';
import { Link } from 'react-router-dom';

const bannerStyle = {
  width : '100%',
  height: '750px',
  backgroundColor : 'pink',
  position: 'relative'
};

class Banner extends Component{
  constructor (props) {
    super(props);
  }

  render(){
    return(
      <div id="venuemonkBanner" style={{backgroundImage: 'url("https://cdn.venuemonk.com/images/banner/vm-corporate-banner-image-3.png")', display: 'flex', alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: '450px'}}>
        <div className="container">
          <div className="row home-search">
            <div className="col-6" style={{color: '#fff'}}>
              <div style={{marginTop: '10px', fontSize: '20px'}}>{`
                Join a community of 2000+ venue owners and service providers who are growing as our business partners.
              `}</div>
              <div style={{display: 'flex', marginTop: '45px'}}>
                <div>
                  <div style={{fontSize: '24px', fontWeight: 600}}>{`20000+`}</div>
                  <div style={{fontSize: '20px'}}>{`Verified Events`}</div>
                </div>
                <div style={{marginLeft: '30px'}}>
                  <div style={{fontSize: '24px', fontWeight: 600}}>{`20000+`}</div>
                  <div style={{fontSize: '20px'}}>{`Verified Venues`}</div>
                </div>
                <div style={{marginLeft: '30px'}}>
                  <div style={{fontSize: '24px', fontWeight: 600}}>{`20+`}</div>
                  <div style={{fontSize: '20px'}}>{`Category of Events`}</div>
                </div>
              </div>
              <div style={{marginTop: '65px'}}>
                <Link to="/list-your-business" className="btn btn-white" >{`LIST YOUR BUSINESS`}</Link>
              </div>
            </div>
            <div className="col-5 offset-1">
              {
                !this.props.isAuthenticated ?
                  <LoginForm/>:
                  <div className="col-sm-12 text-center" style={{backgroundColor: '#fff', paddingBottom: '25px', borderRadius: '3px', minHeight: '200px', paddingTop: '30px'}}>
                    <div style={{width: '100%'}}>
                      <label >{`You are Logged In`}</label>
                    </div>
                    <div style={{width: '100%', marginTop: '30px'}}>
                      <Link to={`/dashboard`} className="btn btn-primary full-width" style={{width: '80%'}}>{`VISIT DASHBOARD`}</Link>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

}
 export default Banner;
