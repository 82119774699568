import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVenueSpaceList } from 'modules/App/AppActions';
import { getVenueSpaceList } from 'modules/App/AppReducer';
import { userNotify } from 'components/Toasts/ToastAdd';

const CloseWonLeadForm = ({ closedWonReasons, handleCloseWonEvent, leadId, isRejectingLead, leadid }) => {
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [reasonForCloseWon, setReasonForCloseWon] = useState('');
  const [otherReasonValue, setOtherReasonValue] = useState('');
  const [bookedDates, setBookedDates] = useState([
    { booking_date: "", bookedPrice: '', guestCount: '', bookingslot: '', venuespace: '' }
  ]);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [bookingPaymentDue, setBookingPaymentDue] = useState('');
  const [bookingNote, setBookingNote] = useState('');
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const slotbooking = [
    { label: 'Dinner', value: 'Dinner' },
    { label: 'Lunch', value: 'Lunch' },
  ];

  const dispatch = useDispatch();

  const venuespacelist = useSelector(state => getVenueSpaceList(state));


  useEffect(() => {
    if(leadid){
    dispatch(fetchVenueSpaceList(leadid))
    }
  }, [leadid]);


  const handleChangeClosereason = (keyValue) => {
    if (keyValue === 'Other reason') {
      setIsOtherReason(true);
      setReasonForCloseWon(keyValue);
    } else {
      setIsOtherReason(false);
      setReasonForCloseWon(keyValue);
    }
  };

  const handleChange = (index, field, value) => {

    const updatedBookedDates = [...bookedDates];
    updatedBookedDates[index][field] = value;
    setBookedDates(updatedBookedDates);
  };

  const handleAddBooking = () => {
    setBookedDates([...bookedDates, { booking_date: "", bookedPrice: '', guestCount: '', bookingslot: '', venuespace: '' }]);
  };

  const handleRemoveBooking = (index) => {
    setBookedDates(bookedDates.filter((_, i) => i !== index));
  };

  const showMessage = (msg, msgtype) => {
    userNotify(msg, msgtype)
  };

  const checkValidWonLead = (updatedLead) => {
    let isValid = true;

    if (!updatedLead.conversion_factor || updatedLead.conversion_factor === '') {
      isValid = false;
      showMessage('Please give a reason for rejection.', 'warning');
    } else if (!updatedLead.booking_note || updatedLead.booking_note.trim() === '') {
      isValid = false;
      showMessage('Please enter booking notes.', 'warning');
    } else if (!updatedLead.payment_amount || isNaN(updatedLead.payment_amount) || updatedLead.payment_amount <= 0) {
      isValid = false;
      showMessage('Please enter a valid payment amount.', 'warning');
    } else if (!updatedLead.payment_due || isNaN(updatedLead.payment_due) || updatedLead.payment_due <= 0) {
      isValid = false;
      showMessage('Please enter a valid payment due amount.', 'warning');
    }
    return isValid;
  };

  const wonLead = () => {
    const updatedLead = {
      conversion_factor: isOtherReason ? otherReasonValue : reasonForCloseWon,
      booked_dates: bookedDates.map(data => ({
        booked_event_date: data.booking_date ? moment(data.booking_date).toISOString() : null,
        booked_price: data.bookedPrice,
        booked_guest_count: data.guestCount,
        slot_booked: data.bookingslot,
        venuespace_name: data.venuespace.label,
        venuespace_id: data.venuespace.value,
      })),
      payment_amount: paymentAmount,
      payment_due: bookingPaymentDue,
      booking_note: bookingNote,
      isVenueAvailableForEventDate: false,
      venueShow: false,
      isFrozen: true,
      venueReject: false,
      conversion_date: moment().utc().utcOffset(330).toISOString(),
    };
    if (checkValidWonLead(updatedLead)) {
      handleCloseWonEvent(leadId, updatedLead);
    }
  };

  const venuespace = venuespacelist.map(item => ({ label: item.name, value: item._id }));

  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-12">
          <label>Closed Won Reason</label>
          <select className="form-control" onChange={(e) => handleChangeClosereason(e.target.value)}>
            <option value="">-- Select Reason --</option>
            {closedWonReasons && closedWonReasons.length > 0 && closedWonReasons.map(item => (
              <option key={item.value} value={item.value}>{item.label}</option>
            ))}
            <option key={'Other reason'} value="Other reason">Other reason</option>
          </select>
        </div>

        {isOtherReason && (
          <div className="col-12" style={{ marginTop: '15px' }}>
            <label>Other Reason</label>
            <textarea
              defaultValue={''}
              className="form-control"
              onChange={(e) => setOtherReasonValue(e.target.value)}
            />
          </div>
        )}
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <label>Booked Event Date</label>
          <div>
            <Button style={{ maxWidth: '70px', height: '30px', fontSize: '13px' }} onClick={handleAddBooking}>Add</Button>
          </div>
        </div>
        {bookedDates.map((booking, index) => (

          <div key={index} style={{ border: '1px solid #EEEEEE', padding: '20px', marginTop: '30px', borderRadius: '5px' }}>

            <Button style={{ maxWidth: '70px', height: '30px', fontSize: '13px', paddingBottom: '10px' }} onClick={() => handleRemoveBooking(index)}>remove</Button>

            <div style={{ marginTop: '10px' }}>
              <label>Booking Date</label>
              <DatePicker
                style={{ width: '100%' }}
                format={"DD MMM YYYY"}
                value={booking.booking_date ? dayjs(booking.booking_date) : null}
                onChange={(date) => date && handleChange(index, "booking_date", date.startOf('day').toISOString())}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <label >{'Booked Price'}</label>
              <input
                type="number"
                defaultValue={''}
                className="form-control"
                aria-label="Phone"
                aria-describedby="userSms-addon"
                onChange={(e) => handleChange(index, 'bookedPrice', e.target.value)}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <label >{'Booked Guest Count'}</label>
              <input
                type="number"
                defaultValue={''}
                className="form-control"
                aria-label="Phone"
                aria-describedby="userSms-addon"
                onChange={(e) => handleChange(index, 'guestCount', e.target.value)}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <label >{'Slot Booked'}</label>
              <Select style={{ width: '100%' }} placeholder="Select slot" labelInValue={true} onChange={(slot) => handleChange(index, 'bookingslot', slot.value)} value={booking.bookingslot} options={slotbooking} className='selectStyles' />

            </div>
            {venuespace && venuespace.length > 0 &&
              <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                <label >{'Venue Space'}</label>
                <Select style={{ width: '100%' }} placeholder="Select venueSpace" labelInValue={true} onChange={(space) => handleChange(index, 'venuespace', space)} value={booking.venuespace} options={venuespace} className='selectStyles' />
              </div>
            }
          </div>
        ))}

        <div className="col-12" style={{ marginTop: '15px' }}>
          <label>Note For Bookings</label>
          <textarea
            defaultValue={''}
            className="form-control"
            onChange={(e) => setBookingNote(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ marginTop: '15px' }}>
          <label>Payment Advance</label>
          <input
            type="number"
            defaultValue={''}
            className="form-control"
            onChange={(e) => setPaymentAmount(e.target.value)}
          />
        </div>

        <div className="col-12" style={{ marginTop: '15px' }}>
          <label>Payment Due</label>
          <input
            type="number"
            defaultValue={''}
            className="form-control"
            onChange={(e) => setBookingPaymentDue(e.target.value)}
          />
        </div>
      </div>
      {isNotificationShown && (
        <div className="row">
          <div className="col-12" style={{ marginTop: '25px', marginBottom: '5px' }}>
            <div className="alert-success text-center">Congratulations!</div>
          </div>
        </div>
      )}
      <div style={{ position: 'relative', display: 'block', marginTop: '15px', width: '100%' }}>
        <Button disabled={isRejectingLead} className="btn btn-green" style={{ width: '100%' }} onClick={() => wonLead()}>
          {isRejectingLead ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : isNotificationShown ? 'Confirm' : 'Close - Won'}
        </Button>
      </div>
    </div>
  );
};

export default CloseWonLeadForm;

