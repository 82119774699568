import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {imageKitUrl} from '../../constants/constants'
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader'
import NewHeader from "./NewHeader";
import {Space} from 'antd'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserDropdownOpen: false,
            isSidebarHidden: false,
            isShowVenueDropdown: false
        };
    }

    handleVenueChange = (venue) => {
        this.setState({
            isShowVenueDropdown: false
        });
        localStorage.setItem('active_venue', JSON.stringify(venue));
        this.props.handleChangeActiveVenue(venue);
    };

    handleToggleDropdown = () => {
        this.setState({
            isShowVenueDropdown: !this.state.isShowVenueDropdown
        });
    };

    goToDashboard = () => {
        this.props.navigate('/dashboard')
    };

    render() {
        return (
            <div className="row" style={{margin: 0, width: '100%', height: '100%'}}>
                <div className="col-sm-12" style={{padding: '0px'}}>
                    {!this.props.isAuthenticated || (this.props.windowWidth > this.props.breakpoint) ? <Link to="/">
                        <span style={{padding: '5px 10px', position: 'absolute'}}>
                          <img style={{
                              width: (this.windowWidth > this.breakpoint) ? '100%' : '60%',
                              marginTop: (this.windowWidth > this.breakpoint) ? '0px' : '10px'
                          }} src={`${imageKitUrl}/images/logo/vm-venuemonk-logo.png`} alt=""/>
                        </span>
                    </Link> : null}
                    {
                        this.props.isAuthenticated ?
                            <div className={'pull-right'} style={{marginRight: '20px', gap: '30px', display: 'flex'}}>
                                <NewHeader
                                    windowWidth={this.props.windowWidth}
                                    breakpoint={this.props.breakpoint}
                                    venueList={this.props.venueList}
                                    venueDetails={this.props.venueDetails}
                                    activeVenue={this.props.activeVenue}
                                    handleChangeActiveVenue={this.handleVenueChange}
                                />
                                <ProfileHeader
                                    windowWidth={this.props.windowWidth}
                                    breakpoint={this.props.breakpoint}
                                />
                            </div>
                            :
                                <Space className={'pull-right'} size={'middle'}>
                                    <Link className={'pull-right'} to={`/login`} style={{color: '#2A52BB', margin: '18px 20px 18px 18px'}}>LOGIN</Link>
                                    {
                                        (!this.props.isAuthenticated && (this.props.windowWidth > this.props.breakpoint)) ?
                                            <Link to="/list-your-business" style={{marginLeft: '20px', margin: '10px 40px 10px 0px', height: '40px'}} className="btn btn-primary pull-right">{`LIST YOUR BUSINESS`}</Link> : null
                                    }
                                </Space>

                    }
                </div>
            </div>

        );
    }
}


export default Header;
