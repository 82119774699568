import { lazy } from "react";
import { Route } from "react-router-dom";
import App from "./modules/App.js";
import AuthWrapper from "./modules/AuthWrapper.js";
import NotAuthWrapper from "./modules/NotAuthWrapper.js";
import HomePage from "./modules/App/pages/HomePage/HomePage";
import AvailablityCalendar from "modules/Calendar/pages/AvailabilityCalendar.js";
import PageAccessControl from "./components/PermissionLocks/PageAccessControl";
import LeadManageModal from "modules/LeadManage/components/LeadManage.js";
import LeadManage from "modules/LeadManage/components/LeadManage.js";
import LeadManagePage from "./modules/LeadManage/pages/LeadManagePage";

if (process.env.NODE_ENV !== "production") {
  // Require async routes only in development for react-hot-reloader to work.
  import("./modules/App/pages/HomePage/HomePage");
  import("./modules/App/pages/LoginPage/LoginPage");
}

const LoginPage = lazy(() =>
  import("./modules/App/pages/LoginPage/LoginPage.js")
);
const TermsPage = lazy(() => import("./modules/App/pages/Terms/TermsPage.js"));
const ForgotPasswordPage = lazy(() =>
  import("./modules/App/pages/ResetPasswordPage/ForgotPasswordPage.js")
);
const ResetPasswordPage = lazy(() =>
  import("./modules/App/pages/ResetPasswordPage/ResetPasswordPage.js")
);
const ListYourBusinessPage = lazy(() =>
  import(
    "./modules/ListYourBusiness/pages/ListYourBusiness/ListYourBusinessPage.js"
  )
);
const PaidPartnerSubscriptionEnd = lazy(() =>
  import(
    "./modules/ListYourBusiness/pages/PaidPartnerSubscription/PaidPartnerSubscription.js"
  )
);
const PaidPartnerSubscription = lazy(() =>
  import(
    "./modules/ListYourBusiness/pages/PaidPartnerSubscription/PaidPartnerSubscription.js"
  )
);
const PrevenueFormPage = lazy(() =>
  import(
    "./modules/ListYourBusiness/pages/PrevenueFormPage/PrevenueFormPage.js"
  )
);

const DashBoard = lazy(() =>
  import("./modules/App/pages/DashBoard/DashBoard.js")
);
const BasicInfoPage = lazy(() =>
  import("./modules/Venue/pages/BasicInfoPage/BasicInfoPage.js")
);
const LocationPage = lazy(() =>
  import("./modules/Venue/pages/LocationPage/LocationPage.js")
);
const CapacityPage = lazy(() =>
  import("./modules/Venue/pages/CapacityPage/CapacityPage.js")
);
const PricePage = lazy(() =>
  import("./modules/Venue/pages/PricePage/PricePage.js")
);
const MenuListPage = lazy(() =>
  import("./modules/Venue/pages/MenuListPage/MenuListPage.js")
);
const PackageListPage = lazy(() =>
  import("./modules/Venue/pages/PackageListPage/PackageListPage.js")
);
const VenueApprovalPage = lazy(() =>
  import("./modules/Venue/pages/VenueApprovalPage/VenueApprovalPage.js")
);
const CuisinesPage = lazy(() =>
  import("./modules/Venue/pages/CuisinesPage/CuisinesPage.js")
);
const LeadList = lazy(() => import("./modules/Lead/LeadListPage/LeadList.js"));
const BasicDetailPage = lazy(() =>
  import("./modules/LeadManage/pages/BasicDetailPage/BasicDetailPage.js")
);
const PackagePage = lazy(() =>
  import("./modules/LeadManage/pages/PackagePage/PackagePage.js")
);
const LeadPackageListPage = lazy(() =>
  import("./modules/LeadManage/pages/PackageListPage/PackageListPage.js")
);
const VisitPage = lazy(() =>
  import("./modules/LeadManage/pages/VisitPage/VisitPage.js")
);
const BookingListPage = lazy(() =>
  import("./modules/Booking/BookingListPage/BookingListPage.js")
);
const MemberListPage = lazy(() =>
  import("./modules/Member/pages/MemberListPage/MemberListPage.js")
);
const MemberPermissionUpdator = lazy(() =>
  import(
    "./modules/Member/pages/MemberPermissionUpdator/MemberPermissionUpdator.js"
  )
);
const AnalyticsPage = lazy(() =>
  import("./modules/Analytics/pages/AnalyticsPage")
);
const SubscriptionPage = lazy(() =>
  import("./modules/Subscription/pages/SubscriptionPage")
);
const CalendarPage = lazy(() =>
  import("./modules/Calendar/pages/CalendarPage")
);
const EmailVerificationPage = lazy(() =>
  import(
    "./modules/ListYourBusiness/pages/EmailVerificationPage/EmailVerificationPage"
  )
);

export const routes = (
  <>
    <Route path="/" element={<App />}>
      <Route index element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<NotAuthWrapper />}>
        <Route path=":type/confirm-email" element={<EmailVerificationPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/forgot" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/list-your-business" element={<ListYourBusinessPage />} />
        <Route
          path="/paid-partner/:id/subscription-end"
          element={<PaidPartnerSubscriptionEnd />}
        />
        <Route
          path="/paid-partner/new-subscription"
          element={<PaidPartnerSubscription />}
        />
        <Route path="/list-your-business/:id" element={<PrevenueFormPage />} />
      </Route>
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/dashboard" element={<DashBoard />} />
        {/* <Route path="/venue/info" element={<BasicInfoPage />} />
        <Route
          path="/venue/location"
          element={<LocationPage />}
          shouldHavePermissions={["venue:read"]}
        />
        <Route
          path="/venue/capacity"
          element={<CapacityPage />}
          shouldHavePermissions={["venue:read"]}
        />
        <Route
          path="/venue/price"
          element={<PricePage />}
          shouldHavePermissions={["venue:read"]}
        />
        <Route
          path="/venue/menu/list"
          element={<MenuListPage />}
          shouldHavePermissions={["venue:read"]}
        />
        <Route
          path="/venue/package/list"
          element={<PackageListPage />}
          shouldHavePermissions={["venue:read"]}
        />
        <Route
          path="/venue/partnerapproval/list"
          element={<VenueApprovalPage />}
          shouldHavePermissions={[""]}
        />
        <Route
          path="/venue/cuisines"
          element={<CuisinesPage />}
          shouldHavePermissions={["venue:read"]}
        /> */}
        <Route path="/lead/list" element={<LeadList />} />
        <Route path="/event/:leadId/detail" element={<LeadManagePage />} />
        <Route path="/event/:leadId/package" element={<PackagePage />} />
        <Route
          path="/event/:leadId/package/list"
          element={<LeadPackageListPage />}
        />
        <Route path="/event/:leadId/visit" element={<VisitPage />} />
        <Route path="/booking/list" element={<BookingListPage />} />
        <Route path="/member/list" element={<MemberListPage />} />
        <Route
          path="/member/:id/update"
          element={<MemberPermissionUpdator />}
        />
        {/*<Route path="/:venueid/subscription" element={<SubscriptionPage />} />*/}
        {/*<Route path="/:venueid/analytics" element={<AnalyticsPage />} />*/}
        <Route path="/:venueid/calendar" element={<AvailablityCalendar />} />
      </Route>
    </Route>
  </>
);
