import React,{Component} from 'react';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const getStartStyle = {
  width : '100%',
  backgroundColor : '#2A52BB',
  position: 'relative',
  color: '#fff'
};

class GetStart extends Component{
  constructor (props) {
    super(props);
  }

  render(){
    return(
      <div className="col-sm-12 text-center">
        <h2 style={{padding:'5px'}}>Join Us to Grow Your Business</h2>
        <div style={{padding:'20px 20px 30px 20px'}}>
          <Link to="/list-your-business"  className="btn btn-white" style={{width:'300px'}}>
            Get Started
          </Link>
        </div>
      </div>
    );
  }

}
export default GetStart;
