import React from 'react';
import {CURRENT_STAGE} from "../../../../constants/enum";
import meta from '../../../../constants/meta.json'

const footerStyle = {
  marginTop: "20px"
};

const linkSocial = {listStyle: "none", display: "inline", paddingRight: "5px", color: '#fff'};

export function Footer() {
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div style={{backgroundColor: '#FAFAFA', margin: 0, position: 'absolute', height: '150px', bottom: 0, width: '100%', paddingTop: '30px', paddingBottom: '20px'}}>
      <div className="col-sm-12 text-center" style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: '33%'}}>

        </div>
        <div style={{width: '33%'}}>
          <div style={{fontSize: '24px', color: '#404040'}}><a href={'https://www.venuemonk.com/'}>{`VenueMonk ${CURRENT_STAGE.slice(0, 1).toUpperCase()}  ${meta.version}`}</a></div>

        </div>
        <div style={{width: '33%'}}>

        </div>
      </div>
      <div className="col-sm-12 text-center" style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
              <span className="link-social" style={linkSocial}><a href="https://www.facebook.com/venuemonk"><i
                className="fa fa-facebook-official fa-2x" style={{color: '#2a52bb'}}></i></a></span>
              <span className="link-social"  style={linkSocial}><a href="https://twitter.com/venuemonk"><i style={{color: '#2a52bb'}} className="fa fa-twitter-square fa-2x"></i></a>
              </span>
              <span className="link-social"  style={linkSocial}><a href="https://www.instagram.com/venuemonk/"><i style={{color: '#2a52bb'}}
                className="fa fa-instagram fa-2x"></i></a></span>
              <span className="link-social"  style={linkSocial}><a href="https://www.pinterest.com/VenueMonk/"><i style={{color: '#2a52bb'}}
                className="fa fa-pinterest-square fa-2x"></i></a></span>
              <span className="link-social"  style={linkSocial}><a href="https://www.linkedin.com/company/venuemonk"><i style={{color: '#2a52bb'}}
                className="fa fa-linkedin-square fa-2x"></i></a></span>
              <span className="link-social"  style={linkSocial}><a href="https://plus.google.com/+Venuemonk"><i style={{color: '#2a52bb'}}
                className="fa fa-google-plus-square fa-2x"></i></a></span>
              <span className="link-social"  style={linkSocial}><a href="https://www.youtube.com/channel/UCD5nUACOmzFrW1dnS1mpaNw"><i style={{color: '#2a52bb'}}
                className="fa fa-youtube-square fa-2x"></i></a></span>
      </div>
    </div>
  );
}

export default Footer;
